import React, { useState, useContext, useEffect, useRef } from 'react'
import 'react-day-picker/dist/style.css'
import './styles/eta_form.css'
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';
import { loadCaptchaEnginge, LoadCanvasTemplate, LoadCanvasTemplateNoReload, validateCaptcha } from 'react-simple-captcha';
import moment from 'moment';
import 'bootstrap/dist/css/bootstrap.min.css';


export function Validate_form() {
  const [data, setData] = useState([]);
  const [tripIDvalue, setTripValue] = useState();
  const [orderIDvalue, setOrderValue] = useState();
  const [validTrip, setValidTrip] = useState(false);
  const [validOrder, setValidOrder] = useState(false);
  const [showErrorMessage, setShowError] = useState(false);

  let  validKey=true;
  const isEmpty = Object.keys(data).length === 0;
  let initialRender = useRef(true);

  let erroJson = '[]'

  function validateNumber(event) {
     var key = window.event ? event.which : event.keyCode;
    
    var numberRegex = /^\d+$/;
    
    if(numberRegex.test(event.key) || (key == 8 || key == 46) || ((key >= 37 && key <= 40))){
      validKey = true;
    }
    else{
      validKey = false;
      event.key='';
      event.preventDefault();
    }

    return validKey;
};

  function handleTripChange(event) {

    var tripValue = event.target.value;
    if (tripValue.length > 5) {
      ApiCall(event.target.value, null);
      setTripValue(tripValue);
    }
  }

  function handleOrderChange(event) {
    setShowError(false); //clears error message when typing in OrderID textbox.
    setOrderValue(event.target.value);

  }

  function handleOrderVerificartion(event) {
    ApiCall(null, orderIDvalue);
  }
  function ApiCall(tripId, orderNumber) {

    //Initialize the configuration of the headers used when calling the API.
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("accept", "*/*");

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    // Gets the URL depending on the environment to call the right versoin of the Function App.
    var baseUrl = process.env.REACT_APP_MULE_API_BASE_URL;
    var apiUrl = baseUrl;

    if (orderNumber) {
      apiUrl += "/logisticsetaapi/ValidateOrderTrip?tripId=" + tripIDvalue + "&orderId=" + orderNumber;
      console.log(apiUrl);
    }
    else if(tripId){
      apiUrl += "/logisticsetaapi/ValidateTripId?tripID=" + tripId;
      console.log(tripId);
    } else{
      console.log('Null tripId');
      return;
    }

    //Executes API call
    fetch(apiUrl, requestOptions)
      .then(response => {
        //console.log(response);
        if (!response.ok) {

          if (tripId && !orderNumber) {
            setValidTrip(false);
            console.log('Invalid trip');
            setShowError(true);
          }
          if (orderNumber) {
            console.log('Invalid  - Order');
            setShowError(true);
          }

        } else {
          //If tripID is valid, changes flag used to enable second textbox to enter OrderId 
          if (tripId) {
            setValidTrip(true);
          }
          //If OrderID is valid, changes flag used to check the OrderId status. 
          if (orderNumber) {

            setValidOrder(true);
          }
        }

      })
      .catch(error => console.log('error', error));

  }

  useEffect(() => {
    if (!initialRender) {
      ApiCall()
    }
  }, []);

  return (
    <div>
      <div class="container border-top" hidden={validOrder && validTrip}>
      <h1 class="title" >Logistics Driver ETA</h1>
        <div class="row">
          {(!validTrip && tripIDvalue && showErrorMessage) || (!validOrder && orderIDvalue && showErrorMessage) ? <Invalid_Info /> : null}

        </div>
        <div class="row"></div>
        <div class="row">
        <div class="col-sm-3 pe-5">
          <label class="font-weight-bold">Trip Number</label>

          </div>
          <div class="col-25">
            <input type="text" inputMode="numeric" pattern="[0-9]*" name='tripValue' onChange={handleTripChange} maxLength={7} onKeyPress={validateNumber} onKeyUp={validateNumber} onKeyDown={validateNumber}/>
          </div>

        </div>
        <br></br>
        <div class="row">
        <div class="col-sm-3 pe-5">
          <label class="font-weight-bold">Order Number</label>

          </div>
          <div class="col-25">
            <input type="text" inputMode="numeric" pattern="[0-9]*" name='orderValue'  onKeyUp={handleOrderChange} disabled={!validTrip ? "disable" : ""} maxLength={7} onKeyDownCapture={validateNumber}/>
          </div>
        </div>
        <br></br>
        <div class="row">
        
          <div class="col-15">
            <button type="button" class="btn btn-danger btn-md" onClick={handleOrderVerificartion}>Verify</button>
          </div>
        </div>
       

      </div>

      {validOrder && validTrip ? <Eta_form trip={tripIDvalue} order={orderIDvalue} /> : null}
      {!validOrder && !validTrip && tripIDvalue === null ? <Invalid_Info /> : null}

    </div>


  )
}

export function Invalid_Info() {

  return (
    <label class="error"> Invalid values. Couldn't find trip data related with entered information. </label>
  )
}

const Error_part = ({ error_msg }) => {
  return (
    <label class="error"> {error_msg} </label>
  )
}

export function Success_Message() {

  return (
    <div class="success long-row">
      <label> Success!! ETA information saved correctly. </label>
    </div>
  )
}

const Eta_form = ({ trip, order }) => {
  const orderTypes = [{ label: "--Select Type --", value: "-1" },{ label: "LLD", value: "1" }, { label: "XDL", value: "2" }, { label: "LUL", value: "3" }, { label: "XDU", value: "4" }]
  const [startDate, setStartDate] = useState(undefined);
  const [startTime, setTime] = useState('');
  const [datesAvailable, setDates] = useState([]);
  const [moveType, setMoveType] = useState(-1);
  const [successSave, setSuccessSave] = useState(false);
  const options = { year: 'numeric', month: 'long', day: 'numeric', timeZone: "UTC" };
  const [validations, setErrors] = useState({});
  const [captcha, setCaptcha] = useState('');
  const [errorLabel, setErrorLabel] = useState('');
  const [executingSave, setExecuting] = useState(false);

  function handleDayChange(event) {
    setStartDate(event.target.value)
  }

  function ApiSaveCall(formData) {

    //Initialize the configuration of the headers used when calling the API.
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("accept", "*/*");

    //Converts to Json the values coming from the form.
    let objJson = JSON.stringify(formData);
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      redirect: 'follow',
      body: objJson
    };

    // Gets the URL depending on the environment to call the right versoin of the Function App.
    var baseUrl = process.env.REACT_APP_MULE_API_BASE_URL;
    var apiUrl = baseUrl + "/logisticsetaapi/SaveETA?code=" + process.env.REACT_APP_SAVE_CODE;

    //Executes API call to Save ETA values.
    fetch(apiUrl, requestOptions)
      .then(response => {
        if (!response.ok) {
          setErrorLabel('Failed to save Trip ETA information');
          console.log("Error saving");
          setSuccessSave(false);
          setExecuting(false);
        } else {
          console.log("Saved");
          setErrorLabel('');
          setSuccessSave(true);

          setTimeout(() => {
            document.location.reload();
          }, 4000);
        }

      })
      .catch(error => {
        console.log('error', error);
        setErrorLabel('Failed to save Trip ETA information');
        setSuccessSave(false);
        setExecuting(false);
      });
  }

  function handleMoveType(event) {
    setMoveType(event.target.value);
  }

  //This function sets the current date + 3 days forward that will be used to generate UI buttons with date to choose.
  function setupAvailableDate() {

    let initialTime = new Date()
      , arrDates = []
      ;

    arrDates.push(initialTime.toISOString().split("T")[0]);

    for (let i = 1; i <= 3; i++) {
      initialTime.setDate(initialTime.getDate() + 1);
      arrDates.push(initialTime.toISOString().split("T")[0]);
    }

    setDates(arrDates);

  }

  useEffect(() => {
    setupAvailableDate();
    // Loads the captcha component with 6 characters
    loadCaptchaEnginge(6,'white','black','lower');
  }, [])


  function handleTripChange(event) {
    let lowerCaptcha = event.target.value.toLowerCase();
    setCaptcha(lowerCaptcha);
  }

  function handleSubmit(event) {
    event.preventDefault();
    let errors = {};
    setErrors(errors);

    setExecuting(true);

    // sets the structure of the data object that will be passed to the API
    var dataObj = {
      "tripNumber": "",
      "orderNumber": "",
      "eventType": 1,
      "arrivalDate": ""
    };

    // Validates required fields 
    if (!trip) {
      errors["tripID"] = 'Please verify TripID value.';
      console.log(errors["tripID"]);
    }

    if (!order) {
      errors["orderID"] = 'Please verify OrderID value.';
      console.log(errors["orderID"]);
    }

    if (moveType === -1) {
      errors["moveType"] = 'Please select a Move Type.';
      console.log(errors["moveType"]);
    }

    if (!startDate) {
      errors["startDate"] = 'Please select a Date ';
      console.log(errors["startDate"]);
    }

    if (!startTime) {
      errors["startTime"] = 'Please select a Time ';
      console.log(errors["startTime"]);
    }

    if (validateCaptcha(captcha) == false) {
      errors["captcha"] = 'Captcha verification is required.'
    }

    if (errors.moveType || errors.orderID || errors.startDate || errors.startTime || errors.captcha) {
      setErrors(errors);
      setExecuting(false);
    }
    else {
      dataObj.tripNumber = trip;
      dataObj.orderNumber = order;
      dataObj.eventType = parseInt(moveType);
      var momentDate=moment(startDate).valueOf();

      //Sets and format Datetime 
      let formattedDate = new Date(momentDate);
      formattedDate.setUTCHours(startTime.hour());
      dataObj.arrivalDate = formattedDate;

      ApiSaveCall(dataObj);
    }

  }

  return (

    <div class='container'>

      {successSave ? <Success_Message /> : null}
      {(!successSave) ? <Error_part error_msg={errorLabel} /> : null}

      <form onSubmit={handleSubmit}>
        <div class="row">
            <div class="col-md-6">
            <label class="font-weight-bold">Trip Number&nbsp;&nbsp;&nbsp;&nbsp; :</label>
            &nbsp;   
              <span class="idLabel">{trip}</span>
            </div>
            
        </div>
    
        <div class="row">
        
            <div class="col-md-6">
            <label class="font-weight-bold">Order Number&nbsp; :</label>
            &nbsp;
            <span class="idLabel">{order}</span>
          </div>          
        </div>

        <div class="row">
        <div class="col-md-6">
           <label class="font-weight-bold">Stop Event &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; :</label>           
          &nbsp;&nbsp;&nbsp;&nbsp;
            <select name="orderTypes" class="move_type" onChange={handleMoveType}>
              {orderTypes.map((moveType) => (
                <option key={moveType.value} value={moveType.value}>{moveType.label}</option>
              ))}
            </select>
          </div>
        </div>
       
        <div class="row">
          <div class="col-25"><span style={{ color: "red" }}>{validations["moveType"]}</span>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <label class="font-weight-bold">Date&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  :</label>            
              <ul class="date-option">
                   {datesAvailable.map((date, index) => (
                <li>
                  <input id={date} name={date} value={date} type="radio" onChange={handleDayChange} checked={startDate === date} />
                  <label for={date}>{new Date(date).toLocaleDateString([], options)}</label>
                </li>
                ))}
             </ul>
          </div>
        </div>
        <div class="row"><div class="col-25"> <span style={{ color: "red" }}>{validations["startDate"]}</span></div> </div>
        <div class="row">
        <div class="col-md-6">
        <label class="font-weight-bold">ETA Time&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:</label>            
         
            &nbsp;&nbsp;        
            <TimePicker style={{ width: 100 }} showMinute={false} showSecond={false} className="timepicker" onChange={setTime} />
          </div>
        </div>
        <div class="row"><div class="col-50"><span style={{ color: "red" }}>{validations["startTime"]}</span></div></div>
        <div class="row captcha">
          <div class="col-md-6">  <label class="font-weight-bold">Captcha</label>            
                &nbsp;&nbsp;&nbsp;
            <input type="text" id="captchaInput" onChange={handleTripChange}></input>
            <LoadCanvasTemplateNoReload />
          </div>
        </div>
        <div class="row"><div class="col-50"><span style={{ color: "red" }}>{validations["captcha"]}</span></div></div>
        <div class="row">
          <div class="col-15">
            <button type="submit" class="desktop" disabled={executingSave}>Save ETA</button>
          </div>
        </div>
        <div class="mobile-div">
          <button type="submit" class="mobile" disabled={executingSave}>Save ETA</button>
        </div>
      </form>
    </div>
  )
}